import React from "react";
import { Container, Row, Col, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import Logo from "../../assets/images/logo-icon.svg";

window.intercomSettings = {
  api_base: "https://api-iam.intercom.io",
  app_id: "nedui962",
};
// We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/nedui962'
(function () {
  var w = window;
  var ic = w.Intercom;
  if (typeof ic === "function") {
    ic("reattach_activator");
    ic("update", w.intercomSettings);
  } else {
    var d = document;
    var i = function () {
      i.c(arguments);
    };
    i.q = [];
    i.c = function (args) {
      i.q.push(args);
    };
    w.Intercom = i;
    var l = function () {
      var s = d.createElement("script");
      s.type = "text/javascript";
      s.async = true;
      s.src = "https://widget.intercom.io/widget/nedui962";
      var x = d.getElementsByTagName("script")[0];
      x.parentNode.insertBefore(s, x);
    };
    if (document.readyState === "complete") {
      l();
    } else if (w.attachEvent) {
      w.attachEvent("onload", l);
    } else {
      w.addEventListener("load", l, false);
    }
  }
})();
const Footer = () => {
  return (
    <>
      <footer className="footer bg-light pt-10 pb-9 pt-md-11 pb-md-10 pt-xl-12 pb-xxl-11 pb-xxxl-12 pt-xxxl-13">
        <Container>
          <Row className="justify-content-center justify-content-md-start gy-9 gy-md-0 gx-md-12 gx-xxl-13 mb-5 mb-md-12 mb-xxl-13 mb-xxxl-14">
            <Col xs="auto" className="me-md-auto">
              <img src={Logo} className="footer-logo" alt="Eldery Life logo" />
            </Col>
            <Col md="6" lg="5" xxl="4">
              <div className="small fw-semibold text-dark text-center text-md-start mb-5 mb-md-6">
                Eldery Life
              </div>
              <Nav
                as="ul"
                className="justify-content-center justify-content-md-start flex-md-column small"
              >
                <Nav.Item as="li" className="mx-5 mb-2 mx-md-0 mb-md-4">
                  <Nav.Link href="/home">Home</Nav.Link>
                </Nav.Item>
                <Nav.Item as="li" className="mx-5 mb-2 mx-md-0 mb-md-4">
                  <Nav.Link href="/our-mission">Our mission</Nav.Link>
                </Nav.Item>
                <Nav.Item as="li" className="mx-5 mx-md-0 mb-md-4">
                  <Nav.Link href="/how-it-works">How it works</Nav.Link>
                </Nav.Item>
                <Nav.Item as="li" className="mx-5 mx-md-0 mb-md-4">
                  <Nav.Link href="/plans">Plans</Nav.Link>
                </Nav.Item>
                {/* <Nav.Item as="li" className="mx-5 mx-md-0 mb-md-4">
                  <Nav.Link href="#">Support</Nav.Link>
                </Nav.Item> */}
                <Nav.Item as="li" className="mx-5 mx-md-0">
                  <Nav.Link href="/blog">Blog</Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col md="8" lg="7" xxl="5">
              <div className="small fw-semibold text-dark text-center text-md-start mb-5 mb-md-6">
                Legal
              </div>
              <Nav
                as="ul"
                className="justify-content-center justify-content-md-start flex-md-column small"
              >
                <Nav.Item as="li" className="mx-5 mb-2 mx-md-0 mb-md-4">
                  <Nav.Link href="/terms-and-conditions">
                    Terms and conditions
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li" className="mx-5 mx-md-0 mb-md-4">
                  <Nav.Link href="/privacy-policy">Privacy policy</Nav.Link>
                </Nav.Item>
                <Nav.Item as="li" className="mx-5 mx-md-0">
                  <Nav.Link href="/Cookie-policy">Cookie policy</Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col md="auto" className="text-center text-md-start">
              <div className="small fw-semibold text-dark mb-5 mb-md-6">
                Social
              </div>
              <Link to="/">
                <i className="fa-brands fa-facebook lead"></i>
              </Link>
              <Link to="/" className="ms-5">
                <i className="fa-brands fa-linkedin lead"></i>
              </Link>
            </Col>
          </Row>
          <Row className="align-items-end justify-content-center justify-content-between gy-3 gy-md-0 pt-5 pt-md-9 pt-xl-10">
            <Col md className="order-md-last">
              <div className="small-xs text-center text-md-end">
                Built with love <span className="ms-1">&#x2764;&#xFE0F;</span>
              </div>
            </Col>
            <Col>
              <div className="small-xs text-center text-md-start">
                &copy; 2024 Elderly Life Ltd. All rights reserved.
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};

export default Footer;
