import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import listingArr from "./BlogData.json"; // Import JSON file
import Header from "../../header/Header";
import Footer from "../../footer/Footer";
import Loader from "../../loader/Loader";
import { Col, Container, Row } from "react-bootstrap";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  PinterestShareButton,
  WhatsappShareButton,
  RedditShareButton,
} from "react-share";
import {
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  PinterestIcon,
  WhatsappIcon,
  RedditIcon,
} from "react-share";

const BlogDetails = () => {
  const { id } = useParams();
  const [content, setContent] = useState("");
  const [loading, setloading] = useState(true);
  const matches = listingArr.filter((item) => {
    return item.postID === parseInt(id);
  });

  useEffect(() => {
    setTimeout(() => {
      setloading(false);
    }, 2500);
  }, []);
  useEffect(() => {
    if (matches) {
      fetch(`/blogPosts/${matches[0].postID}.md`) // Load the Markdown file dynamically
        .then((response) => {
          if (!response.ok) {
            throw new Error("Markdown file not found");
          }
          return response.text();
        })
        .then((text) => setContent(text))
        .catch((error) => console.error("Error loading markdown file:", error));
    }
  }, [matches]); // Runs when ID changes

  const shareUrl = "https://yourblogpage.com";
  const title = "Check out this amazing blog!";

  return (
    <>
      <Header />
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="hero pb-0">
            <Container>
              <Row className="justify-content-center blogdetails">
                <Col xl="21">
                  <p
                    className="text-center mb-0 fw-black fs-20"
                    style={{ color: "#003A6F" }}
                  >
                    {matches &&
                    matches[0] &&
                    Array.isArray(matches[0].categories)
                      ? matches[0].categories[0]
                      : matches[0].categories}
                  </p>
                  <h3 className="h2 my-4 text-center">
                    {matches && matches[0] && matches[0].title}
                  </h3>
                  <div className="text-center lead-xl mb-7 mb-xl-9">
                    {matches && matches[0] && matches[0].description}
                  </div>
                  <div className="d-flex align-items-center justify-content-between text-dark">
                    <div className="d-flex align-items-center">
                      {}
                      <img
                        src={matches && matches[0] && matches[0].authorPic}
                        alt="React Logo"
                        title="author"
                      ></img>
                      <div>{matches && matches[0] && matches[0].author}</div>
                    </div>{" "}
                    <div>
                      {matches && matches[0] && matches[0].datePublished}
                    </div>
                  </div>
                  <div className="mb-10rem">
                    <ReactMarkdown className="text-dark markdown ">
                      {content}
                    </ReactMarkdown>
                    <div className="mt-10 mb-2 text-dark">
                      Share this article
                    </div>
                    <div style={{ display: "flex", gap: "10px" }}>
                      <FacebookShareButton url={shareUrl} quote={title}>
                        <FacebookIcon size={32} round />
                      </FacebookShareButton>
                      <LinkedinShareButton url={shareUrl}>
                        <LinkedinIcon size={32} round />
                      </LinkedinShareButton>
                      <TwitterShareButton url={shareUrl} title={title}>
                        <TwitterIcon size={32} round />
                      </TwitterShareButton>
                      <PinterestShareButton url={shareUrl} title={title}>
                        <PinterestIcon size={32} round />
                      </PinterestShareButton>
                      <WhatsappShareButton url={shareUrl} title={title}>
                        <WhatsappIcon size={32} round />
                      </WhatsappShareButton>
                      <RedditShareButton url={shareUrl} title={title}>
                        <RedditIcon size={32} round />
                      </RedditShareButton>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <Footer />
        </>
      )}
    </>
  );
};

export default BlogDetails;
