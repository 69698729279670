import { Button, Card } from "react-bootstrap";

const PlanCard = ({ planType, keyType }) => {
  console.log(planType);
  console.log(keyType);

  return (
    <Card className="border-0 shadow position-relative overflow-hidden">
      {planType.name === "Family" && (
        <span className="card-ribbon fw-bold text-dark small text-center py-2 mb-0">
          Best value
        </span>
      )}
      <Card.Body className="p-6 p-md-7">
        <h2 className="h5 mb-4 mb-md-5">
          <span>{planType.name}</span>
        </h2>
        <div className="fs-3 text-info fw-bold lh-sm mb-0">
          <span>£{planType.price}</span>
        </div>
        <div className="small text-dark mb-5 mb-md-6">
          per {keyType === "yearly" ? "year" : "month"}
        </div>
        <ul className="list-unstyled list-checked text-dark mb-5 mb-md-6">
          <li className="list-item d-flex align-items-center mb-2">
            <i className="fa fa-solid fa-check text-success"></i>
            <span className="ms-3">{planType.availableSeats} main users</span>
          </li>
          <li className="list-item d-flex align-items-center mb-2">
            <i className="fa fa-solid fa-check text-success"></i>
            <span className="ms-3">
              {planType.availableSeats} support users
            </span>
          </li>
          <li className="list-item d-flex align-items-center mb-2">
            <i className="fa fa-solid fa-check text-success"></i>
            <span className="ms-3">Cancel any time</span>
          </li>
          <li className="list-item d-flex align-items-center">
            <i className="fa fa-solid fa-check text-success"></i>
            <span className="ms-3">No hidden costs</span>
          </li>
        </ul>
        <Button
          variant="primary"
          className="w-100"
          href="https://app.elderlylife.com/signup"
          target="_blank"
        >
          Sign up free
        </Button>
      </Card.Body>
    </Card>
  );
};

export default PlanCard;
