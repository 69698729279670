import React, { useEffect, useState } from "react";
import { Row, Col, Button, Image, Container } from "react-bootstrap";
import PlanCard from "../plans/PlanCard";
import motherHuggingDaughter from "../../assets/images/index/mother-hugging-daughter.jpg";
import elderlyCoupleEmbracing from "../../assets/images/index/elderly-couple-embracing.jpg";
import familySharingMeal from "../../assets/images/index/family-sharing-meal.jpg";
import Loader from "../loader/Loader";

const PricingPlanCallToAction = () => {
  const [key, setKey] = useState("yearly");
  const [accountPlanData, setAccountPlanData] = useState([]);
  const [loading, setloading] = useState(true);
  useEffect(() => {
    const apiUrl =
      "https://developmentapi.elderlylife.com/accountPlan/plan/list";

    fetch(apiUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json(); // Convert response to JSON
      })
      .then((data) => {
        setAccountPlanData(data.AccountPlanData);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    setTimeout(() => {
      setloading(false);
    }, 500);
  }, []);
  return (
    <section>
      <div className="bg-primary-light pt-11 pb-10 pt-md-12 pb-md-13 pt-xl-13 pt-xxxl-14">
        <Container className="mb-lg-8 mb-xl-10 mb-xl-12 mb-xxxl-13">
          <Row className="align-items-center gy-5 pb-11 pb-md-12 pb-xl-13 pb-xxxl-14">
            <Col className="col-24 col-xl-9 col-xxl-10 me-xl-6 me-xxl-8">
              <h2 className="mb-5 mb-md-6">
                Pricing plans that work for everyone.
              </h2>
              <p className="text-dark mb-5 mb-md-6">
                Plans designed to work for all circumstances, from individuals
                wanting better visibility over finances to entire families
                working together.
              </p>
              <Button variant="dark btn-underline" href="/plans">
                Learn more
              </Button>
            </Col>
            {/* <Col className="col-24 col-md-8 col-lg gy-10 gx-md-5 gy-md-11 gy-xl-0">
              <PlanCard planType="single" />
            </Col>
            <Col className="col-24 col-md-8 col-lg gx-md-5 gy-md-11 gy-xl-0">
              <PlanCard planType="couple" />
            </Col>
            <Col className="col-24 col-md-8 col-lg gx-md-5 gy-md-11 gy-xl-0">
              <PlanCard planType="family" />
            </Col> */}
            <Col className="col-24 col-md-8 col-lg gy-10 gx-md-5 gy-md-11 gy-xl-0">
              <div className="d-flex justify-content-center mb-6 mb-md-7 mb-xl-8 mb-xxxl-9">
                <div className="border bg-white rounded-2 d-flex gap-1 p-2">
                  {/* <Button variant="primary">Annual (save 37%)</Button> */}
                  {/* <Button variant="light">Monthly</Button> */}
                  <button
                    className={`btn newBtn rounded-2 ${
                      key !== "monthly" ? "btn-primary newBtnActive" : ""
                    }`}
                    onClick={() => setKey("yearly")}
                  >
                    Annual <br className="d-sm-none" />
                    (save 37%)
                  </button>
                  <button
                    className={`btn newBtn rounded-2 ${
                      key === "monthly" ? "btn-primary newBtnActive" : ""
                    }`}
                    onClick={() => setKey("monthly")}
                  >
                    Monthly
                  </button>
                </div>
              </div>
              {loading ? (
                <Loader />
              ) : (
                <Row className="">
                  {accountPlanData &&
                    accountPlanData
                      .filter((item) => item.renewalType === key)
                      .map((item) => (
                        <Col className="auto">
                          <PlanCard
                            planType={item}
                            keyType={item.renewalType}
                          />
                        </Col>
                      ))}
                </Row>
              )}
            </Col>
          </Row>
        </Container>
      </div>
      <Container fluid className="mt-n11 mt-md-n14">
        <Row className="row-cols-1 row-cols-lg-3 gy-5 gx-3 gx-md-4 gx-lg-5">
          <Col className="mt-lg-n8 mt-xl-n10 mt-xl-n12">
            <div className="ratio ratio-1x1 rounded-3 overflow-hidden">
              <Image
                src={motherHuggingDaughter}
                className="img-object-fit-cover"
                alt=""
              />
            </div>
          </Col>
          <Col className="mt-lg-n8 mt-xl-n10 mt-xl-n12">
            <div className="ratio ratio-1x1 rounded-3 overflow-hidden">
              <Image
                src={elderlyCoupleEmbracing}
                className="img-object-fit-cover"
                alt=""
              />
            </div>
          </Col>
          <Col className="mt-lg-n8 mt-xl-n10 mt-xl-n12">
            <div className="ratio ratio-1x1 rounded-3 overflow-hidden">
              <Image
                src={familySharingMeal}
                className="img-object-fit-cover"
                alt=""
              />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default PricingPlanCallToAction;
