import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Header from "../../header/Header";
import Footer from "../../footer/Footer";
import Loader from "../../loader/Loader";
import PlanCard from "../../plans/PlanCard-lg";

const Plan = () => {
  const [key, setKey] = useState("yearly");
  const [accountPlanData, setAccountPlanData] = useState([]);
  const [loading, setloading] = useState(true);
  useEffect(() => {
    const apiUrl =
      "https://developmentapi.elderlylife.com/accountPlan/plan/list";

    fetch(apiUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json(); // Convert response to JSON
      })
      .then((data) => {
        setAccountPlanData(data.AccountPlanData);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    setTimeout(() => {
      setloading(false);
    }, 500);
  }, []);

  return (
    <>
      <Header />
      {loading ? (
        <Loader />
      ) : (
        <>
          <Container>
            <div className="bg-primary-light rounded-3 p-6 p-md-10 p-xl-11 p-xxl-13 p-xxxl-14 mb-5">
              <Row className="justify-content-center mb-6 mb-md-7 mb-xl-8 mb-xxxl-9">
                <Col xxxl="18">
                  <h1 className="h2 text-center mb-4 mb-md-5">
                    Start free for 7 days then enjoy the plan that best suits
                    your needs.
                  </h1>
                  <p className="lead text-center text-dark mb-0">
                    Choose the best plan for you, from single individuals to
                    entire families.
                  </p>
                </Col>
              </Row>
              <div className="d-flex justify-content-center mb-6 mb-md-7 mb-xl-8 mb-xxxl-9">
                <div className="border bg-white rounded-2 d-flex gap-1 p-2">
                  {/* <Button variant="primary">Annual (save 37%)</Button> */}
                  {/* <Button variant="light">Monthly</Button> */}
                  <button
                    className={`btn rounded-2 ${
                      key !== "monthly" ? "btn-primary" : ""
                    }`}
                    onClick={() => setKey("yearly")}
                  >
                    Annual <br className="d-sm-none" />
                    (save 37%)
                  </button>
                  <button
                    className={`btn rounded-2 ${
                      key === "monthly" ? "btn-primary" : ""
                    }`}
                    onClick={() => setKey("monthly")}
                  >
                    Monthly
                  </button>
                </div>
              </div>
              <Row className="justify-content-center align-items-center gy-5 pb-5 pb-md-9 pb-xl-10 pb-xxxl-11">
                {accountPlanData &&
                  accountPlanData
                    .filter((item) => item.renewalType === key)
                    .map((item) => (
                      <Col className="col-24 col-md-13 col-xl-8 col-xxl-7 col-xxxl-6">
                        <PlanCard planType={item} keyType={item.renewalType} />
                      </Col>
                    ))}
              </Row>
              <div className="text-center">
                <div class="lead mb-1">&#x1F642;</div>
                <p className="text-info fw-semibold mb-0">
                  We want you happy - cancel any time with no stress.
                </p>
              </div>
            </div>
          </Container>
          <Footer />
        </>
      )}
    </>
  );
};

export default Plan;
